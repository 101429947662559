import "../static/css/Pages/home.css";
import img from "../static/images/EnhancedLogo.png";
import svg from "../static/images/Asset2.svg";

const LandingPage = () => {
  return (
    <div className="relative flex justify-center overflow-hidden">
      <div className="w-screen h-[65vh] md:h-[80vh] min-h-[500px] flex justify-center items-center bg-[#a3896c] absolute opacity-20"></div>
      <div className="w-screen h-[65vh] md:h-[80vh] min-h-[500px] flex justify-center items-center main"></div>
      <div className="w-full absolute h-full flex justify-center items-center layer"></div>
      <div className="absolute h-[70vh] flex items-center mt-32 md:-top-20 -top-4">
        <div className="blob-effect aspect-square w-[200px] md:w-[380px]">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div className="flex flex-col p-5 gap-y-2 home-text w-80 absolute items-center">
            <div>
              <img src={img} className="sm:h-48 h-32" id="home-logo" />
            </div>
            {/* <div className="text-start pl-10 pb-5 hidden md:flex">
              <p className="text-sm sm:text-lg">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est
                veniam explicabo tempora.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <img
        src={svg}
        className="mt-48 z-10 absolute -bottom-8 md:bottom-[-100px] w-screen"
      />
    </div>
  );
};

export default LandingPage;
