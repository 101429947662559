import { ImSpinner2 } from "react-icons/im";
import { Link } from "react-router-dom";
import { useProduct } from "../hooks/useProduct";
import { ProductType, dynamicSort } from "../utilities";
import { useEffect, useState } from "react";
import ProductCard from "./ProductCard";

const ProductSection = () => {
  const { isLoading, isError, data } = useProduct({
    category: "",
    home_product: "true",
    recommended: "",
  });

  const [products, setProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    const allData: ProductType[] = [];

    data?.pages.map((group: any) => {
      group.data.results.map((product: ProductType) => {
        allData.push(product);
      });
    });

    setProducts(allData);
  }, [data]);

  return (
    <div className="flex flex-col bg-[#755138] justify-center items-center py-8 overflow-x-auto">
      <div className="flex flex-col items-start justify-end w-[70%]">
        <div className="flex gap-x-4 p-10 overflow-x-auto">
          {isLoading ? (
            <div className="min-h-[20vh] bg-[#755138] flex justify-center items-center">
              <ImSpinner2 size={36} className="spinner" />
            </div>
          ) : isError ? (
            <div className="bg-[#755138] min-h-[20vh] flex justify-center items-center">
              Something went wrong
            </div>
          ) : (
            <div>
              <div className="flex flex-col gap-x-4 px-10 py-20 overflow-x-auto">
                <div className="home-products translate-y-[-4rem]">
                  {products.length > 0
                    ? products
                        .sort(dynamicSort("category"))
                        .map((product: ProductType, index: number) => {
                          return <ProductCard product={product} key={index} />;
                        })
                    : null}
                </div>
              </div>
              <div className="pl-20  absolute">
                <Link to={"/Products"} className="shop-button">
                  Shop Now
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
