import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { URL } from "../utilities";

type useProductProps = {
  category: string;
  home_product: string;
  recommended: string;
};

export const useProduct = ({
  category,
  home_product,
  recommended,
}: useProductProps) => {
  const fetchProduct = async (pageParam: number) => {
    return await axios.get(
      `${URL}/product/api/?page=${pageParam}${
        category ? `&category=${category}` : ""
      }${home_product ? `&home_product=${home_product}` : ""}${
        recommended ? `&recommended=${recommended}` : ""
      }`
    );
  };

  return useInfiniteQuery({
    queryKey: ["products", home_product, category],
    queryFn: ({ pageParam = 1 }) => fetchProduct(pageParam),
    getNextPageParam: (lastPage: any, pages) => {
      return parseInt(lastPage?.data?.next?.slice(-1))
        ? parseInt(lastPage?.data?.next?.slice(-1))
        : null;
    },
    initialPageParam: 1,
    select: (data) => data,
  });
};
