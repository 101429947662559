import { useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";
import "../static/css/components/navigation.css";
import Logo from "../static/images/Ke'a-Tibebat-Logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { RiShoppingBasketFill } from "react-icons/ri";
import { MdOutlineManageSearch } from "react-icons/md";
import { TiShoppingCart } from "react-icons/ti";
import { useCartStore } from "../hooks/useCartStore";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdAccountCircle } from "react-icons/md";
import { useAuthStore } from "../hooks/useAuthStore";
import { useCategory } from "../hooks/useCategory";
import { CategoryType, dynamicSort } from "../utilities";

const Navigation = () => {
  const checkboxRef: any = useRef();

  const [shadow, setShadow] = useState("none");
  const [showlogo, setShowLogo] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  const { cart } = useCartStore();
  const { user } = useAuthStore();

  const { data, isLoading, isError } = useCategory({ queryKey: ["category"] });

  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    if (location.pathname === "/") {
      let supportPageOffset = window.pageXOffset !== undefined;
      let isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
      let scroll = {
        x: supportPageOffset
          ? window.pageXOffset
          : isCSS1Compat
          ? document.documentElement.scrollLeft
          : document.body.scrollLeft,
        y: supportPageOffset
          ? window.pageYOffset
          : isCSS1Compat
          ? document.documentElement.scrollTop
          : document.body.scrollTop,
      };

      let scrollPercentage =
        (scroll.y /
          (document.documentElement.offsetHeight - window.innerHeight)) *
        100;

      if (scrollPercentage > 7) setShowLogo(true);
      else setShowLogo(false);
    } else setShowLogo(true);

    if (window.scrollY >= 10) {
      setShadow("2xl");
    } else {
      setShadow("none");
    }
  };

  useEffect(() => {
    if (location.pathname === "/") setShowLogo(false);
    else setShowLogo(true);

    window.addEventListener("scroll", handleScroll);
  }, [location.pathname]);

  return (
    <div
      className={`flex flex-row justify-between item-center fixed w-full shadow-${shadow} nav-bar z-20`}
      style={{
        height: 90,
        backgroundColor: "transparent",
        transition: "0.4s ease-in-out",
      }}
    >
      <div className="flex flex-row items-center justify-center px-5 py-3 relative left-nav">
        <input
          ref={checkboxRef}
          type="checkbox"
          id="activate-sidebar"
          className="hidden"
        />
        <label htmlFor="activate-sidebar" id="overlay"></label>
        <label htmlFor="activate-sidebar">
          <AiOutlineMenu size={21} className="hover:cursor-pointer" />
        </label>
        <ul className="absolute top-0 flex flex-col gap-x-8 gap-y-8 p-5 sm-p-10 items-center w-[calc(100vw-110px)] max-w-[600px] h-screen bg-[#d7e8b6] sidebar">
          <li className="self-end">
            <label htmlFor="activate-sidebar">
              <RxCross1 size={24} className="hover:cursor-pointer" />
            </label>
          </li>
          <li>
            <NavLink
              onClick={() => {
                checkboxRef.current.focus();
                checkboxRef.current.checked = false;
              }}
              to={"/"}
            >
              <p className={"text-lg"}>Home</p>
            </NavLink>
          </li>
          <li>
            <div className="relative flex justify-center">
              <div
                className="flex justify-center items-center  hover:cursor-pointer"
                onClick={() => setShowCategory(!showCategory)}
              >
                <p className={"text-lg"}>Product Class</p>
                <IoMdArrowDropdown />
              </div>
              <div
                className={`${
                  showCategory ? "" : "hidden"
                } bg-[#edfad4] absolute w-56 flex flex-col items-center top-8 gap-y-2 rounded py-2`}
              >
                {isError
                  ? "Something Went Wrong"
                  : isLoading
                  ? "Loading"
                  : data.length > 0
                  ? data
                      .sort(dynamicSort("order_number"))
                      .map((category: CategoryType, index: number) => {
                        return (
                          <div
                            className="hover:cursor-pointer"
                            style={{ fontSize: 15 }}
                            onClick={() => {
                              checkboxRef.current.focus();
                              checkboxRef.current.checked = false;
                              setShowCategory(false);
                              navigate("/Products", {
                                state: {
                                  searchQuery: category.name,
                                  isSearch: false,
                                },
                              });
                            }}
                            key={index}
                          >
                            {category.name}
                          </div>
                        );
                      })
                  : "No Category"}
              </div>
            </div>
          </li>
          <li>
            <NavLink
              onClick={() => {
                checkboxRef.current.focus();
                checkboxRef.current.checked = false;
              }}
              to={"/Contact"}
            >
              <p className={"text-lg"}>Contact Us</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                checkboxRef.current.focus();
                checkboxRef.current.checked = false;
              }}
              to={"/About"}
            >
              <p className={"text-lg"}>About Ke'a-Tibebat</p>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="flex gap-x-4 md:gap-x-8">
        <NavLink
          className="flex flex-col justify-center items-center"
          to={"/ProductsCategory"}
        >
          <MdOutlineManageSearch className="nav-icons" />
          <span className="nav-icons-text">Category</span>
        </NavLink>
        <NavLink
          className="flex flex-col justify-center items-center"
          to={"/Products"}
        >
          <RiShoppingBasketFill className="nav-icons" />
          <span className="nav-icons-text">Shop</span>
        </NavLink>
        <NavLink
          className="flex flex-col justify-center items-center relative"
          to={"/Cart"}
        >
          <div className="absolute top-[10px] right-[-13px] bg-[#38271a] p-2 rounded-full w-6 h-6 flex justify-center items-center">
            <p className="text-[#e5d3b3]">{cart.length}</p>
          </div>
          <TiShoppingCart className="nav-icons" />
          <span className="nav-icons-text">Cart</span>
        </NavLink>
        <NavLink
          className="flex flex-col justify-center items-center relative"
          to={"/Account"}
        >
          {user.id ? (
            <div className="h-5 w-5 md:h-7 md:w-7 rounded-full bg-[#38271a] flex justify-center items-center">
              {user.profile ? (
                <img src={user.profile} />
              ) : (
                <h1 className="nav-icons-text text-[#e5d3b3]">
                  {user?.name[0]?.toUpperCase()}
                </h1>
              )}
            </div>
          ) : (
            <MdAccountCircle className="nav-icons" />
          )}
          <span className="nav-icons-text">Account</span>
        </NavLink>
      </div>
      <div className="flex flex-col justify-center px-5 py-3">
        <NavLink to={"/"}>
          <img
            src={Logo}
            className={`${
              showlogo ? "opacity-100" : "opacity-0"
            } transition duration-500 w-[60px] md:w-[70px]`}
          />
        </NavLink>
      </div>
    </div>
  );
};

export default Navigation;
