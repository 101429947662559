import { useEffect } from "react";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
import { useAuthStore } from "../hooks/useAuthStore";
import axios from "axios";
import { FaHistory } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { useOrder } from "../hooks/useOrder";

export const Account = () => {
  const navigate = useNavigate();

  const { user, setUser } = useAuthStore();

  const logout = async () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    setUser({ id: null, name: "", email: "", phone_number: "", profile: "" });
  };

  useEffect(() => {
    if (!user.id) navigate("/Account/Login");
  }, [user]);

  return (
    <div className="py-48 bg-[#75975e] min-h-screen flex flex-col items-center justify-center gap-y-8">
      {user.id ? (
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            <Link
              to={"/Account/OrderHistory"}
              className="flex flex-col justify-center items-center hover:scale-110 hover:cursor-pointer transition duration-300"
            >
              <div className="bg-[#402c1f] px-4 rounded-t-xl">
                <h1 className="text-[#E5D3B3]">Order History</h1>
              </div>
              <div className="h-64 w-80 md:h-72 md:w-96 bg-[#edfad4] rounded-xl overflow-hidden flex justify-center items-center">
                <FaHistory size={64} className="text-[#b1c687]" />
              </div>
            </Link>
            <Link to={"/Account/Profile"}>
              <div className="flex flex-col justify-center items-center hover:scale-110 hover:cursor-pointer transition duration-300">
                <div className="bg-[#402c1f] px-4 rounded-t-xl">
                  <h1 className="text-[#E5D3B3]">Profile</h1>
                </div>
                <div className="h-64 w-80 md:h-72 md:w-96 bg-[#edfad4] rounded-xl flex  overflow-hidden justify-center items-center">
                  <MdAccountCircle size={72} className="text-[#b1c687]" />
                </div>
              </div>
            </Link>
          </div>
          <div>
            <button className="red-button" onClick={logout}>
              Logout
            </button>
            <Link to={"/Account/Login"} className="green-button1">
              Switch Account
            </Link>
          </div>
        </div>
      ) : null}
      <ScrollRestoration />
    </div>
  );
};
