import { ScrollRestoration } from "react-router-dom";

export const About = () => {
  return (
    <div className="px-4 md:px-10 py-[15vh] min-h-screen bg-[#75975e] flex flex-col gap-y-4 privacy-policy">
      <div>
        <h1 className="font-bold text-4xl">Ke’A Tibebat</h1>
        <p>
          Welcome to Ke'a Tibebat, a home to remarkable selections of
          thoughtfully crafted artefacts and healthcare products. Our diverse
          collections highlight the beauty of nature-inspired lifestyles,
          featuring herbal remedies, captivating paintings, intricately designed
          pottery, pure leather products and exquisite clothes. Each item
          embodies our dedication to functionality and sustainability, merging
          modern needs seamlessly with traditional practices.
        </p>
        <p>
          Come and experience the simplicity of nature’s wisdom and the
          brilliance of peoples’ talents in every piece of our products.
        </p>
      </div>

      <div>
        <h2 className="font-bold text-2xl">Our Mission</h2>
        <p>
          At Ke'a Tibebat, our mission is to go back to the roots of humans’
          best and organic craftsmanship and share the rich talents of artisans
          to the world. We strive to create a better platform for the needs of
          our time, promoting the use of nature’s life-packed ingredients in
          everything we create.
        </p>
      </div>

      <div>
        <h2 className="font-bold text-2xl">The Birth of Ke'A Tibebat</h2>
        <p>
          When a significant amount of people in a given society reach the
          highest level of universal wisdom and live by such extraordinary
          thoughts, great civilizations flourish. Time has witnessed such
          heights of human civilization at different intervals and places.
        </p>
        <p>
          Ancient wisdom defines the height of a given society or people
          achieved based on seven discrete parameters; System/order, Affluence,
          Name/identity, Power, Unity, Wisdom/knowledge and Rest.
        </p>
        <p>
          We can see very few countries in the world today can pass the criteria
          to some degree of satisfaction. Since people in the past reached that
          level of development and enjoyed the pleasure of it, how come we found
          ourselves in such critically ill-suited state now?{" "}
        </p>
        <p>
          In such tough time, the ‘Thought of Addmesh’ arises in Ethiopia by a
          simple man called Teninet Setegn, who happened to be a robust seeker
          of ancient wisdom. Few people can reach to the extent of the source,
          draws first-hand ideas, and translate them to the visible world for
          the benefits of all. The man is one who satisfies the saying, “tough
          times create strong people”.
        </p>
        <p>
          The emergence of such wisdom in such times flickers light to the
          current human society who came to a halt from its long journey, with
          utter confusion not knowing how to move forward.
        </p>
        <p>
          So far, these thoughts has been published in books, taught in group
          classes, broadcasted in FM radio shows since 2011. Along with the
          locals, the books are translated into English language with the aim of
          reaching out the global audience.
        </p>
        <p>
          Now the thought has entered to a second phase called practical
          applications of wisdom. One of the manifestation of this new chapter
          is the opening of Ke’A Tibebat Centre. The centre targets primarily on
          finding out those instruments & techniques of wisdom that enabled
          those ancient people great ones hundred years back. Not only finding
          them, but also producing them here & now for the exact same purposes
          of elevating human existence in this world.
        </p>
      </div>
      <div>
        <h2 className="font-bold text-2xl">The Workshop</h2>
        <p>
          Ke’A workshop serves as a hub for collaboration and networking among
          artisans and craftsmen. It offers a shared space for talented mind and
          gifted hands to foster their unique ideas and creativity. Apart from a
          working area, it is a place where they exchange ideas, knowledge, and
          techniques, raising a sense of community and camaraderie.{" "}
        </p>
        <p>
          With an area laid on 260 square meters, the space accommodates
          different sectors of production:
        </p>
        <ul className="list-disc px-12 py-4">
          <li>Pottery</li>
          <li>Essential oil extraction unit</li>
          <li>Traditional cloth weaving and sewing</li>
          <li>Painting and sculpturing</li>
          <li>Herbal health section</li>
        </ul>
      </div>
      <div>
        <h2 className="font-bold text-2xl">The Retail</h2>
        <p>
          Set adjacent to the workshop, painted in 7 shades of green from bottom
          up, the store boasts a unique feature of artistry and a comforting
          mood to walk-in customer. The display tables are crafted with the red
          African juniper wood blended with leather works, giving the room a
          sense of antiquity.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-3xl">PRODUCT DEVELOPMENT</h1>
        <p>
          Our product development philosophy majorly revolves around easing
          humans’ challenges by addressing both the physical and the
          non-physical dimensions of life.
        </p>
        <p>
          Modern lifestyle has increased the risk of various physical, mental
          and psychological health problems. The unhealthy living routines in
          the modern world has exposed us to various illnesses, disabilities and
          even early deaths.
        </p>
        <p>
          It has been found that millions of deaths across the globe are
          occurring due to the choices of the modern lifestyle. Hence, it is
          only important to make appropriate changes to the modern lifestyle by
          exploring solutions from the nature’s wide-ranging buffet.
        </p>
        <p>
          At the heart of our vision is a dedication to herbal-based remedies,
          connecting you with mother nature’s exuberant gifts.
        </p>
      </div>
      <div>
        <h2 className="font-bold text-2xl">PRODUCT CATEGORIES</h2>
        <ol className="list-decimal px-12 py-4">
          <li>
            <div>
              <b>Herbal Remedies: </b>
              <p>
                Herbal medicine aims to return the body to a state of natural
                balance so that it can heal itself, featuring adaptogens and
                curated herb blends. Some of the gifts on our herbal section
                are: Herb for all-purpose gut health, Herb of Light – for the
                eyes, Herb of Breath – for nasal problems, Shinet – for the
                heart, Ashwagandha – for stress and anxiety, and various
                essential oil extracts – flaxseed, pumpkin seeds, clove, common
                rue, rosemary, fennel seeds.
              </p>
            </div>
          </li>
          <li>
            <div>
              <b>Artistic Creations: </b>
              <p>
                Artwork is a powerful tool that can transform a plain and dull
                living space into an aesthetically pleasing haven. The colors,
                patterns, and designs of the artwork can add depth and dimension
                to the room, making it appear more vibrant and lively. Ke’A’s
                art section brings you the captivating paintings, sculptures and
                artistic clay works of talented artists.
              </p>
            </div>
          </li>
          <li>
            <div>
              <b>Clothing & Jewellery: </b>
              <p>
                A garment, embroidery, or a jewellery item represent the
                authentic heritage of previous generations, customs, and morals
                of the people. Traditional costumes have great cultural and
                social value in people’s social and spiritual life. Our
                collections of traditional clothes and jewellery sets display
                variety of casual wears and occasional attires; such as full
                habesha dress, tops, embroidered coats, shawls, etc.
              </p>
            </div>
          </li>
          <li>
            <div>
              <b>Literature: Exploring wisdom, cosmology, and mythology </b>
              <p>
                Literature and music reflects the state of intelligence of a
                given society. The genre of books that dominate book markets
                defines how people think and explore their world from within or
                without. Knowing the power of thoughts from the start, Ke’A’s
                books’ section displays full of the ‘Thoughts of Addmesh’, the
                books that relate the entirety of the universe from a whole
                different perspective.
              </p>
            </div>
          </li>
        </ol>
      </div>
      <ScrollRestoration />
    </div>
  );
};
