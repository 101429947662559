import { useEffect, useState } from "react";
import { CategoryType, dynamicSort } from "../utilities";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { CategoryCard } from "../components/CategoryCard";
import { CiSearch } from "react-icons/ci";
import { useCategory } from "../hooks/useCategory";

export const Category = () => {
  const { isLoading, isError, data } = useCategory({
    queryKey: ["category"],
  });

  const [inputText, setInputText] = useState("");

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="bg-[#755138] min-h-screen flex justify-center items-center">
        <ImSpinner2 size={36} className="spinner" />
        <ScrollRestoration />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-screen flex justify-center bg-[#755138] items-center">
        Something Went Wrong
        <ScrollRestoration />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="h-screen flex justify-center bg-[#755138] items-center">
        No Categories Yet!
        <ScrollRestoration />
      </div>
    );
  }

  return (
    <div className="py-32 min-h-screen bg-[#755138] flex flex-col items-center gap-y-10">
      <div className="h-14 py-4 px-8 w-[320px] flex border-2 border-solid border-[#38271a] rounded-[45px] justify-between items-center">
        <input
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          type="search"
          className="h-10 bg-transparent focus:outline-none text-lg text-[#FBEED7]"
          placeholder="Search..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              (e.target as HTMLElement).blur();
              navigate("/Products", {
                state: { searchQuery: inputText, isSearch: true },
              });
            }
          }}
        />
        <CiSearch
          size={26}
          color="#38271a"
          className="hover:cursor-pointer"
          onClick={() => {
            navigate("/Products", {
              state: { searchQuery: inputText, isSearch: true },
            });
          }}
        />
      </div>
      <div className="boxes gap-10 view mb-16 sm:px-10">
        {data && data?.length > 0
          ? data
              .sort(dynamicSort("order_number"))
              .map((category: CategoryType, index: number) => {
                return <CategoryCard category={category} key={index} />;
              })
          : null}
      </div>
      <ScrollRestoration />
    </div>
  );
};
