import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
import { useCartStore } from "../hooks/useCartStore";
import { ItemType } from "../utilities";
import { PayPal } from "../components/PayPal";
import { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useAuthStore } from "../hooks/useAuthStore";
import toast from "react-hot-toast";

export const Cart = () => {
  const { cart, updateQuantity, clearCart, removeItem } = useCartStore();
  const { user } = useAuthStore();
  const [showCheckOutButton, setShowCheckOutButton] = useState(false);

  const navigate = useNavigate();

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    var totalPrice = 0;
    for (var i = 0; i < cart.length; i++) {
      totalPrice += cart[i].price * cart[i].quantity;
    }
    setTotalPrice(totalPrice);
  });

  return (
    <div className="py-32 bg-[#755138] min-h-screen flex flex-col justify-center items-center w-full gap-y-8">
      {cart.length > 0 ? (
        <div className="flex justify-center items-center">
          <button className="red-button" onClick={() => clearCart()}>
            Clear Cart
          </button>
        </div>
      ) : null}
      <div className="flex flex-col justify-center items-center gap-y-8">
        {cart.length <= 0 ? "No Items Yet!" : null}
        {cart.map((item: ItemType, index: number) => {
          return (
            <div
              className="flex gap-4 w-screen justify-center md:items-center"
              key={index}
            >
              <div className="flex justify-center items-center">
                <button
                  className="red-button2"
                  onClick={() => removeItem(item.id)}
                >
                  <FaTrashAlt />
                </button>
              </div>
              <Link
                to={`/Products/${item.id}`}
                className="hover:opacity-75 transition duration-300"
              >
                <img
                  src={item.image}
                  className="w-32 h-32 md:w-56 md:h-56 object-cover rounded-lg"
                />
              </Link>
              <div className="flex flex-col justify-center items-center">
                <button
                  className="circle-button"
                  onClick={() => updateQuantity(item.id, "plus")}
                >
                  +
                </button>
                <p className="text-white">{item.quantity}</p>

                <button
                  className="circle-button"
                  onClick={() => updateQuantity(item.id, "minus")}
                >
                  -
                </button>
              </div>
              <div className="flex justify-center items-center">
                <p className="text-white">${item.price * item.quantity}</p>
              </div>
            </div>
          );
        })}
      </div>
      {cart.length > 0 ? (
        <div>
          <div className="flex gap-4 justify-center items-center">
            <button
              className="green-button1"
              onClick={() => {
                if (user.id) setShowCheckOutButton(true);
                else {
                  toast.error("You Must Have An Account To Order");
                  navigate("/Account");
                }
              }}
            >
              CheckOut
            </button>
            <p className="text-white">Total Price: ${totalPrice + 1}</p>
          </div>
        </div>
      ) : null}
      {showCheckOutButton && cart.length > 0 ? (
        <PayPal totalPrice={(totalPrice + 1).toLocaleString()} />
      ) : null}
      <ScrollRestoration />
    </div>
  );
};
