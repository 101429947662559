import { useState } from "react";
import { useAuthStore } from "../hooks/useAuthStore";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { URL } from "../utilities";
import { ImSpinner2 } from "react-icons/im";

export const Profile = () => {
  const { user, setUser } = useAuthStore();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");

  const logout = async () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    setUser({ id: null, name: "", email: "", phone_number: "", profile: "" });
  };

  const deleteAccount = async () => {
    setLoading(true);

    try {
      await axios.delete(`${URL}/account/auth/users/me/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        data: {
          current_password: password,
        },
      });
      toast.success("Account Deleted Succefully");
      logout();
      setLoading(false);
      navigate("/Account/Login");
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Something Went Wrong");
    }
    setOpenModal(false);
  };

  return (
    <div className="py-48 bg-[#755138] min-h-screen relative flex items-center justify-center">
      {loading ? (
        <ImSpinner2 size={36} className="spinner" />
      ) : (
        <div className="flex flex-col items-center justify-center gap-y-4 p-4">
          <div className="flex justify-center items-center bg-[#edfad4] py-4 rounded-xl w-80 md:w-[400px]">
            <h1>Your Profile</h1>
          </div>
          <div className="flex flex-col w-full md:w-96 gap-y-1">
            <div className="flex flex-col bg-[#edfad4] p-4 rounded-t-xl">
              <div className="w-1/2">Name:</div>
              <div>{user.name}</div>
            </div>
            <div className="flex flex-col bg-[#edfad4] p-4">
              <div className="w-1/2">Email:</div>
              <div>{user.email}</div>
            </div>
            <div
              className={`flex flex-col ${
                user.phone_number ? "" : "hidden"
              } bg-[#edfad4] p-4 rounded-b-xl`}
            >
              <div className="w-1/2">Phone Number:</div>
              <div>{user.phone_number}</div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div
              onClick={() => setOpenModal(false)}
              className={`fixed w-screen h-screen ${
                openModal ? "block" : "hidden"
              }`}
            ></div>
            <div
              className={`w-72 aspect-square fixed bg-[#402c1f] flex flex-col items-center justify-center gap-y-4 ${
                openModal ? "opacity-100" : "opacity-0 -z-20"
              } transition duration-300 top-[50%] rounded`}
            >
              <h1 className="text-white font-bold text-lg text-center">
                Enter Your Password to Delete Your Account
              </h1>
              <div className="flex justify-center items-center">
                <input
                  required
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
                />
              </div>
              <div className="flex">
                <button
                  className="red-button"
                  disabled={!openModal}
                  onClick={deleteAccount}
                >
                  Delete
                </button>
                <button
                  className="green-button1"
                  disabled={!openModal}
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
            <button className="red-button" onClick={() => setOpenModal(true)}>
              Delete Account
            </button>
          </div>
          <ScrollRestoration />
        </div>
      )}
    </div>
  );
};
