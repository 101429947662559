import Marquee from "react-fast-marquee";

const GallerySection = () => {
  return (
    <div className="bg-[#D7E8B6] flex flex-col justify-center items-center gap-y-5 pb-20">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="relative bottom-[10px]"
      >
        <path
          fill="#755138"
          fill-opacity="1"
          d="M0,32L48,53.3C96,75,192,117,288,144C384,171,480,181,576,154.7C672,128,768,64,864,53.3C960,43,1056,85,1152,90.7C1248,96,1344,64,1392,48L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Marquee speed={20}>
        <div className="flex pl-5">
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_7.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_1988.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_9.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2176.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2168.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2149.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2180.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2179.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2177.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2146.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_6.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2064.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
        </div>
      </Marquee>
      <Marquee speed={25}>
        <div className="flex pl-5">
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_1.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_21.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_4.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_5.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_15.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2648.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2617.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2662.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2663.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_10.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_20.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_2651.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_11.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_12.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_22.jpg")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
          <div className="h-[30vh] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img
              loading="lazy"
              src={require("../static/images/IMG_8.png")}
              alt=""
              className="object-cover"
              width={"100%"}
            />
          </div>
        </div>
      </Marquee>
    </div>
  );
};

export default GallerySection;
